
<app-header [nombre]="pagina"></app-header>
<div m-10 fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="10px">
    <mat-card fxFlex.gt-sm="calc(70% - 10px)">
        <mat-card-header>
            <picture mat-card-avatar>
                <source type="image/webp" srcset="assets/img/albums/Kilometro1.webp">
                <img mat-card-avatar src='assets/img/albums/Kilometro1.jpg' alt='Kilómetro 1'>
            </picture>
            <mat-card-title>Kilómetro 1</mat-card-title>
            <mat-card-subtitle>2017</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <mat-accordion>
                <mat-expansion-panel tema>
                    <mat-expansion-panel-header>
                        <mat-panel-title>Gusano Bacán</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row wrap" fxLayoutGap="20px" fxLayout.xs="column">
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Grandes profesores, libros de colores no me podrán enseñar
                                <br>Bellas emociones pueden ser veneno cuando no te saben amar
                                <br>Sacúdeme fuerte, fuera de mi mente porque no te puedo pasar
                                <br>Quise respetarla quise comprenderla pero soy gusano bacán
                            </p>
                            <p class="text-center">
                                Acuérdate de mí, Dios acuérdate de mí
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Esa rebeldía tonta de una niña que recién comienza a pecar
                                <br>Es cierto que nada es como lo pintan y a ti no te pintan más
                            </p>
                            <p class="text-center">
                                Eres un gusano bacán
                            </p>
                            <p class="text-center">
                                Grandes pensadores, químicos y flores no me pueden ayudar
                                <br>Vienes y terminas, me tiras encima toda tu infelicidad
                                <br>Queda en el olvido todo lo vivido cuando te perdí la señal
                                <br>Para conectarme, tengo que olvidarte y mirar la inmensidad
                            </p>
                        </div>
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Acuérdate de mí, Dios acuérdate de mí
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Esa rebeldía tonta de una niña que recién comienza a pecar
                                <br>Es cierto que nada es como lo pintan y a ti no te pintan más
                                <br>Esa rabia enferma que llevan mis venas puede tener mejor final
                                <br>La saco de adentro, la llevo hasta el cielo sin dañar a los demás
                            </p>
                            <p class="text-center">
                                <em>(Solo)</em>
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Esa rebeldía tonta de una niña que recién comienza a pecar
                                <br>Es cierto que nada es como lo pintan y a ti no te pintan más
                                <br>Esa rabia enferma que llevan mis venas puede tener mejor final
                                <br>La saco de adentro, la llevo hasta el cielo sin dañar a los demás
                            </p>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel tema>
                    <mat-expansion-panel-header titulo>
                        <mat-panel-title>Yo Le Diré</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row wrap" fxLayoutGap="20px" fxLayout.xs="column">
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Perdón señor, si lo que canto no es válido para usted
                                <br>O si toco en lo más hondo de su ser
                                <br>¿Y qué hay? Dirá usted, dirá usted
                            </p>
                            <p class="text-center">
                                Alguien me contó, que solo piensa en el trabajo y en el deber
                                <br>Que sufre por el dinero que se fue
                                <br>¿Y qué hay? Dirá usted, mire usted
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Yo le diré, que el presente y el pasado no puede perder
                                <br>Que el futuro es importante por lo que no fue, mire usted
                            </p>
                            <p class="text-center">
                                Alguien me contó, que solo piensa en un producto para vender
                                <br>Que se estresa por ganar y por crecer
                                <br>¿Y qué hay? Dirá usted, mire usted
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Yo le diré, que el presente y el pasado no puede perder
                                <br>que el futuro es importante por lo que no fue, mire usted
                                <br>Le encantará darse cuenta que su vida no termina acá
                                <br>Que hay una emoción que grita en la profundidad de su ser
                            </p>
                        </div>
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                <em>(Solo)</em>
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Yo le diré, que el presente y el pasado no puede perder
                                <br>Que el futuro es importante por lo que no fue, mire usted
                                <br>Le encantará darse cuenta que su vida no termina acá
                                <br>Que hay una emoción que grita en la profundidad de su ser
                            </p>
                            <p class="text-center">
                                Cita con Dios el martes a las seis
                            </p>
                            <p class="text-center">
                                Él le dirá qué hacer
                                <br>Él le dirá qué hacer
                                <br>Él le dirá qué hacer
                            </p>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel tema>
                    <mat-expansion-panel-header titulo>
                        <mat-panel-title>No Me Dejes Ir</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row wrap" fxLayoutGap="20px" fxLayout.xs="column">
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Si aún no has conocido Amor Divino
                                <br>Esta soledad te quiere agarrar para seguir el castigo
                            </p>
                            <p class="text-center">
                                No me dejes ir a ningún lugar, donde tú puedas faltarme
                                <br>Para hacerle frente a la soledad y a su frío delirante
                                <br>Abrázame fuerte, háblame despacio, cúbreme con tu regazo
                                <br>Sálvame de ir a la oscuridad, acompáñame, dame tu Luz
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Si aún no has conocido Amor Divino
                                <br>Esta soledad te quiere agarrar para seguir el castigo
                                <br>Si aún no has conocido Amor Divino
                                <br>La capacidad de poder amar todo lo que se ha perdido
                            </p>
                            <p class="text-center">
                                No me dejes ir a ningún lugar, donde tú no estés presente
                                <br>Yo no puedo sola, yo no soy capaz, como tú fuiste valiente
                                <br>Esta madrugada sin preguntar nada, te entregaste enamorada
                                <br>Para ser la Reina de amor y dolor, te clavaste en este corazón
                            </p>
                        </div>
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Si aún no has conocido Amor Divino
                                <br>Esta soledad te quiere agarrar para seguir el castigo
                                <br>Si aún no has conocido Amor Divino
                                <br>La capacidad de poder amar todo lo que se ha perdido
                            </p>
                            <p class="text-center">
                                <em>(Solo)</em>
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Si aún no has conocido Amor Divino
                                <br>Esta soledad te quiere agarrar para seguir el castigo
                                <br>Si aún no has conocido Amor Divino
                                <br>La capacidad de poder amar todo lo que se ha perdido
                            </p>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel tema>
                    <mat-expansion-panel-header titulo>
                        <mat-panel-title>La Pataleta</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row wrap" fxLayoutGap="20px" fxLayout.xs="column">
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Cada vez que te busqué, acortando las distancias
                                <br>El destino me cayó como paloma en la espalda
                                <br>Si es difícil ser tu amigo, cómo voy a estar contigo
                                <br>Si es difícil ser tu amigo, cómo voy a estar contigo
                            </p>
                            <p class="text-center">
                                Si vamos a confesar, nadie tiene la paciencia
                                <br>De correr para ganar hasta llegar a la meta
                                <br>Y tú te apareces para tirar la primera piedra
                                <br>Y tú te apareces para tirar la primera piedra
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Cada vez que me olvidas muere un poco de mi vida
                                <br>Cada vez que me olvidas muere un poco de mi vida
                            </p>
                        </div>
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Ahora me toca hablar y ya me estoy acordando
                                <br>De una historia singular cuando te llevé en mis brazos
                                <br>Sólo un par de huellas en la arena y pa’ que sigo hablando
                                <br>Sólo un par de huellas en la arena y pa’ que sigo hablando
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Cada vez que me olvidas muere un poco de mi vida
                                <br>Cada vez que me olvidas muere un poco de mi vida
                            </p>
                            <p class="text-center">
                                <em>(Solo)</em>
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Cada vez que me olvidas muere un poco de mi vida
                                <br>Cada vez que me olvidas muere un poco de mi vida
                            </p>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel tema>
                    <mat-expansion-panel-header titulo>
                        <mat-panel-title>Man In Black</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row wrap" fxLayoutGap="20px" fxLayout.xs="column">
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Un hombre de negro bajó a golpear la puerta de mi corazón
                                <br>Con una sonrisa
                                <br>Me tendió la mano y habló de una casa nueva que no es de cartón
                                <br>Sin barro y sin cenizas
                            </p>
                            <p class="text-center">
                                Si tu no quieres venir, me harás volver
                                <br>Por Dios y por Jesús debes tener
                                <br>Vida diferente
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Somos los hijos del amor
                                <br>Bendito aquel que encuentre
                                <br>Alberto Hurtado lleva a Dios
                                <br>En camioneta verde
                                <br>En camioneta verde
                            </p>
                            <p class="text-center">
                                Esta noche vengo señor a la rivera en que me descubrió
                                <br>A buscar tus hijos
                                <br>Vestido de negro también, para ofrecer una vida mejor
                                <br>Y como el santo dijo
                            </p>
                        </div>
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Si tu no quieres venir, me harás volver
                                <br>Por Dios y por Jesús debes tener
                                <br>Vida diferente
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Somos los hijos del amor
                                <br>Bendito aquel que encuentre
                                <br>Alberto Hurtado lleva a Dios
                                <br>En camioneta verde
                                <br>En camioneta verde
                            </p>
                            <p class="text-center">
                                <em>(Solo)</em>
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Somos los hijos del amor
                                <br>Bendito aquel que encuentre
                                <br>Alberto Hurtado lleva a Dios
                                <br>En camioneta verde
                            </p>
                            <p class="text-center">
                                Somos los hijos del amor
                                <br>Bendito aquel que encuentre
                                <br>Alberto Hurtado lleva a Dios
                                <br>En camioneta verde
                                <br>En camioneta verde
                            </p>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel tema>
                    <mat-expansion-panel-header titulo>
                        <mat-panel-title>Mall De Las Almas</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row wrap" fxLayoutGap="20px" fxLayout.xs="column">
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Manos sucias y largas, sin fecha de regreso ni devolución
                                <br>Bocas llenas y amargas, con palabras añejas de revolución
                            </p>
                            <p class="text-center">
                                Amanezco con uno, por la tarde ya te debo dos, algo no funcionó
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Habrá que rezar a Dios y partir haciendo por amor
                                <br>Todo lo que hiciste por alguna otra razón
                                <br>Habrá que rezar a Dios y aprender de cada nuevo error
                                <br>Todo lo que somos puede ser aún mejor
                            </p>
                            <p class="text-center">
                                En el mall de las almas, las vitrinas hermosas, llenas de color
                                <br>Me deslumbran y atrapan, con ofertas baratas de contradicción
                            </p>
                        </div>
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Amanezco con calma, por la tarde no parezco yo, la cara me cambió
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Habrá que rezar a Dios y partir haciendo por amor
                                <br>Todo lo que hiciste por alguna otra razón
                                <br>Habrá que rezar a Dios y aprender de cada nuevo error
                                <br>Todo lo que somos puede ser aún mejor
                            </p>
                            <p class="text-center">
                                Una nueva parada, con la cara lavada y ganas de mirar
                                <br>Todo lo que pisabas, todo lo que aplastabas por ganar y ganar
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Habrá que rezar a Dios y partir haciendo por amor
                                <br>Todo lo que hiciste por alguna otra razón
                                <br>Habrá que rezar a Dios y aprender de cada nuevo error
                                <br>Todo lo que somos puede ser aún mejor
                            </p>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel tema>
                    <mat-expansion-panel-header titulo>
                        <mat-panel-title>Armaduras Partidas</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row wrap" fxLayoutGap="20px" fxLayout.xs="column">
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Disculpe la pretensión, no quisiera ofenderte
                                <br>Pero siendo sincera, tiempo sin verte
                                <br>Desde un rato a esta parte me han venido siguiendo
                                <br>Buscando sofocarme como si fuera incendio
                            </p>
                            <p class="text-center">
                                Si tú me ayudas, podemos hacer
                                <br>Que el sordo escuche y volver a ver
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Templos llenos de armaduras partidas
                                <br>Por los niños y cantos de bienvenida
                                <br>Calles llenas de sonrisas diferentes
                                <br>El abrazo de, el que cree nuevamente
                            </p>
                            <p class="text-center">
                                Disculpe la pretensión, no tengo el derecho
                                <br>Pero siendo sincera ¿Qué te habías hecho?
                                <br>Porque hay dos o tres Judas que no entienden razones
                                <br>Te condenan y pagan justos por pecadores
                            </p>
                        </div>
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Si tú me ayudas, podemos hacer
                                <br>Aseo en la casa y volver a ver
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Templos llenos de armaduras partidas
                                <br>Por los niños y cantos de bienvenida
                                <br>Calles llenas de sonrisas diferentes
                                <br>El abrazo de, el que cree nuevamente
                            </p>
                            <p class="text-center">
                                <em>(Solo)</em>
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Templos llenos de armaduras partidas
                                <br>Por los niños y cantos de bienvenida
                                <br>Calles llenas de sonrisas diferentes
                                <br>El abrazo de, el que cree nuevamente
                            </p>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel tema>
                    <mat-expansion-panel-header titulo>
                        <mat-panel-title>La Respuesta</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row wrap" fxLayoutGap="20px" fxLayout.xs="column">
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Lo que aprendí ya lo perdí, para olvidar la realidad quise volar
                                <br>Aterrizar a la verdad, nada cambió, solo dolió, me quise enterrar
                            </p>
                            <p class="text-center">
                                ¿Quién puso en tu mirada ese brillo y resplandor?
                                <br>¿Quién dijo una palabra que alivió tu dolor?
                                <br>¿Quién puso la esperanza dentro de tu corazón?
                                <br>¿Por qué tu vida se iluminó?
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Su nombre es Dios
                                <br>Frío y abrigo, el maestro y amigo
                                <br>Su nombre es Dios
                                <br>En el camino, convirtió mi destino
                            </p>
                            <p class="text-center">
                                Ya puedo ver, quiero creer, voy a tratar, quiero llegar, puedo cambiar
                                <br>Veo venir uno feliz, me puedes decir
                            </p>
                            <p class="text-center">
                                ¿Quién puso en tu mirada ese brillo y resplandor?
                                <br>¿Quién dijo una palabra que alivió tu dolor?
                                <br>¿Quién puso la esperanza dentro de tu corazón?
                                <br>¿Por qué tu vida se iluminó?
                            </p>
                        </div>
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Su nombre es Dios
                                <br>Frío y abrigo, el maestro y amigo
                                <br>Su nombre es Dios
                                <br>En el camino, convirtió mi destino
                            </p>
                            <p class="text-center">
                                <em>(Solo)</em>
                            </p>
                            <p class="text-center">
                                ¿Quién puso la esperanza dentro de tu corazón?
                                <br>¿Por qué tu vida se iluminó?
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Su nombre es Dios
                                <br>Frío y abrigo, el maestro y amigo
                                <br>Su nombre es Dios
                                <br>En el camino, convirtió mi destino
                                <br>Su nombre es Dios
                                <br>Frío y abrigo, el maestro y amigo
                                <br>Su nombre es Dios
                                <br>En el camino, convirtió mi destino
                            </p>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel tema>
                    <mat-expansion-panel-header titulo>
                        <mat-panel-title>Con Amor</mat-panel-title>
                        <mat-panel-description>Escrita por Óscar Andrade</mat-panel-description>
                    </mat-expansion-panel-header>
                    <div fxLayout="row wrap" fxLayoutGap="20px" fxLayout.xs="column">
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Y si de pronto sientes que algo te ha tapado el sol
                                <br>Y una sombra eclipsa tu interior
                                <br>Si no ves la luz y vagas ciego y sin razón
                                <br>Enciende tu propio corazón
                            </p>
                            <p class="text-center">
                                Que aún a solas en tu noche tus estrellas se verán
                                <br>Como a un sol brillando en su calor
                                <br>Al sentir que el universo es el más grande acto de amor
                                <br>Y tú estás llamado a esta función
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Con amor
                                <br>Así tan simplemente con amor
                                <br>Con amor
                                <br>La misma propia vida es la razón
                            </p>
                            <p class="text-center">
                                Elige un destino y comienza a caminar
                                <br>Despacito y sin mirar atrás
                                <br>Que hay más de un momento que espera en más de un lugar
                                <br>Dibujando otra oportunidad
                            </p>
                            <p class="text-center">
                                Y tú tienes los colores más hermosos pa' pintar
                                <br>La ocasión y en plena libertad
                                <br>Descubrir que el horizonte es más amplio que el rencor
                                <br>Y aceptar lo humano del error
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Con amor
                                <br>Así tan simplemente con amor
                                <br>Con amor
                                <br>La misma propia vida es la razón
                            </p>
                        </div>
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Y si de pronto sientes que la luz de la verdad
                                <br>Te ilumina con su resplandor
                                <br>Goza en el secreto y crece hasta la humildad
                                <br>Que el mundo espera por tu amor
                            </p>
                            <p class="text-center">
                                Por los campos y ciudades, en la guerra o en la paz
                                <br>En los muros o el baño de algún bar
                                <br>Por desiertos estelares desde aqui a la inmensidad
                                <br>Más allá de la imaginación
                            </p>
                            <p class="text-center">
                                Y al que vive en la desgracia, solitario en su temor
                                <br>Con amor le canto esta canción
                                <br>Al rebelde y al tirano y al que vive del terror
                                <br>Con amor escuchen el clamor
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Con amor
                                <br>Así tan simplemente con amor
                                <br>Con amor
                                <br>La misma propia vida es la razón
                            </p>
                            <p class="text-center">
                                <em>(Coro)</em>
                                <br>Con amor
                                <br>Así tan simplemente con amor
                                <br>Con amor
                            </p>
                            <p class="text-center">
                                La misma propia vida es la razón
                            </p>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-card-content>
    </mat-card>
    <div fxFlex.gt-sm="calc(30% - 10px)">
        <iframe mb-20 width="100%" class="mat-elevation-z5" allow="autoplay *; encrypted-media *;" frameborder="0" height="450" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
            src="https://embed.music.apple.com/cl/album/kil%C3%B3metro-1/1237982511?app=music" ></iframe>
    </div>
</div>