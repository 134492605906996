<app-header [nombre]="pagina"></app-header>
<div class="titulo2"><span>Servi<span class="logo-rojo">c</span>entro</span></div>
<!--<picture>
    <div class="titulo"><span>Servi<span class="logo-rojo">c</span>entro</span></div>
    <source type="image/webp" srcset="assets/img/varios/portada.webp">
    <img src='assets/img/varios/portada.jpg' alt='Kilómetro 1' style="max-width: 100%;">
</picture>-->
<ul class="cb-slideshow">
	<li>
		<span>Image portada</span>
	</li>
	<li>
		<span>Image 01</span>
	</li>
	<li>
		<span>Image 02</span>
	</li>
	<li>
		<span>Image 03</span>
	</li>
	<li>
		<span>Image 04</span>
	</li>
	<li>
		<span>Image 05</span>
	</li>
	<li>
		<span>Image 06</span>
	</li>
	<li>
		<span>Image 07</span>
	</li>
	<li>
		<span>Image 08</span>
	</li>
	<li>
		<span>Image 09</span>
	</li>
	<li>
		<span>Image 10</span>
	</li>
	<li>
		<span>Image 11</span>
	</li>
</ul>