<mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-evenly center">
    <div fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.gt-sm="33%" class="p-1">
        <span class="copyright" matTooltip="Music: BY-NC-ND. Images and Album Cover Art: BY">
            <fa-icon [icon]="faCreativeCommons"></fa-icon> <fa-icon [icon]="faCreativeCommonsBy"></fa-icon> <fa-icon [icon]="faCreativeCommonsNc"></fa-icon> <fa-icon [icon]="faCreativeCommonsNd"></fa-icon> Servicentro {{anio}}</span>
    </div>
    <div fxFlex.gt-sm="33%" class="p-1" fxShow.lt-md="false">
        <a mat-mini-fab color="warn" target="_blank" rel="noopener" href="//open.spotify.com/artist/3Saso3ghsK3PYSromArnYW" matTooltip="Spotify" alt="Spotify Servicentro">
            <fa-icon class="mat-icon" [icon]="faSpotify"></fa-icon>
        </a>
        <a mat-mini-fab color="warn" target="_blank" rel="noopener" href="//itunes.apple.com/artist/servicentro/1237982586" matTooltip="Apple Music" alt="Apple Music Servicentro">
            <fa-icon class="mat-icon" [icon]="faApple"></fa-icon>
        </a>
        <a mat-mini-fab color="warn" target="_blank" rel="noopener" href="//soundcloud.com/servicentro" matTooltip="SoundCloud" alt="SoundCloud Servicentro">
            <fa-icon class="mat-icon" [icon]="faSoundcloud"></fa-icon>
        </a>
        <a mat-mini-fab color="warn" target="_blank" rel="noopener" href="//www.facebook.com/Servicentrocl/" matTooltip="Facebok" alt="Facebok Servicentro">
            <fa-icon class="mat-icon" [icon]="faFacebook"></fa-icon>
        </a>
        <a mat-mini-fab color="warn" target="_blank" rel="noopener" href="//www.youtube.com/Servicentrocl" matTooltip="Youtube" alt="Youtube Servicentro">
            <fa-icon class="mat-icon" [icon]="faYoutube"></fa-icon>
        </a>
        <a mat-mini-fab color="warn" target="_blank" rel="noopener" href="//www.instagram.com/servicentrocl/" matTooltip="Instagram" alt="Instagram Servicentro">
            <fa-icon class="mat-icon" [icon]="faInstagram"></fa-icon>
        </a>
        <a mat-mini-fab color="warn" target="_blank" rel="noopener" href="//www.tiktok.com/@servicentrocl/" matTooltip="TikTok" alt="TikTok Servicentro">
            <fa-icon class="mat-icon" [icon]="faTiktok"></fa-icon>
        </a>
    </div>
    <div fxFlex.sm="50%" fxFlex.gt-sm="33%" class="p-1" fxShow.xs="false">
        <a mat-raised-button color="accent" href="mailto:contacto@servicentro.cl">contacto@servicentro.cl</a>
    </div>
</mat-toolbar>