<app-header [nombre]="pagina"></app-header>
<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-evenly center">
    <div>
        <mat-card>
            <mat-card-header>
                <mat-card-title>Kilómetro 1</mat-card-title>
            </mat-card-header>
            <picture>
                <source type="image/webp" srcset="assets/img/albums/Kilometro1.webp">
                <img mat-card-image src='assets/img/albums/Kilometro1.jpg' alt='Servicentro - Kilómetro 1'>
            </picture>
            <mat-card-actions>
                <button mat-raised-button color="accent" routerLink='kilometro-1'>VER</button>
            </mat-card-actions>
        </mat-card>
    </div>
    <div>
        <mat-card>
            <mat-card-header>
                <mat-card-title>Construyamos Esperanza</mat-card-title>
            </mat-card-header>
            <picture>
                <source type="image/webp" srcset="assets/img/albums/ConstruyamosEsperanza.webp">
                <img mat-card-image src='assets/img/albums/ConstruyamosEsperanza.jpg' alt='Servicentro - Construyamos Esperanza'>
            </picture>
            <mat-card-actions>
                <button mat-raised-button color="accent" routerLink='construyamos-esperanza'>VER</button>
            </mat-card-actions>
        </mat-card>
    </div>
    <div>
        <mat-card>
            <mat-card-header>
                <mat-card-title>Otros</mat-card-title>
            </mat-card-header>
            <img mat-card-image src='favicon.ico' alt='Otros'>
            <mat-card-actions>
                <button mat-raised-button color="accent" routerLink='otros'>VER</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>