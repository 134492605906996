<div class="mat-typography" [class.dark-theme]='isDarktheme' fxFlexFill>
    <mat-sidenav-container fullscreen>
        <mat-sidenav #sidenav fxLayout="column">
            <mat-nav-list fxLayout="column">
                <a mat-list-item (click)="sidenav.toggle()">
                    <mat-icon>close</mat-icon>
                    <span>Cerrar</span>
                </a>
                <a mat-list-item (click)="sidenav.toggle()" routerLink='/biografia' routerLinkActive="active-link">Biografía</a>
                <a mat-list-item (click)="sidenav.toggle()" routerLink='/albums' routerLinkActive="active-link">Música</a>
                <a mat-list-item (click)="sidenav.toggle()" routerLink='/media' routerLinkActive="active-link">Media</a>
                <a mat-list-item [matMenuTriggerFor]="social">Siguenos</a>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content>
            <mat-toolbar color="primary">
                <mat-toolbar-row class="mat-elevation-z24" style="z-index: 10;">
                    <div fxShow="true" fxHide.gt-sm="true">
                        <button mat-icon-button (click)="sidenav.toggle()" aria-label="menú" matTooltip="Menú">
                            <mat-icon>menu</mat-icon>
                        </button>
                    </div>
                    <button mat-button routerLink='/' class="titulo">
                        <span>Servi<span class="logo-rojo">c</span>entro</span>
                    </button>
                    <span fxFlex></span>
                    <div fxShow="true" fxHide.gt-sm="true">
                        <button mat-icon-button fxFlex="auto" (click)="themeToggle()">
                            <mat-icon>format_color_fill</mat-icon>
                        </button>
                    </div>
                    <div fxShow="true" fxHide.lt-md="true">
                        <button mat-button fxFlex="auto" routerLink='biografia'>Biografía</button>
                        <button mat-button fxFlex="auto" routerLink='albums'>Música</button>
                        <button mat-button fxFlex="auto" routerLink='media'>Media</button>
                        <button mat-button [matMenuTriggerFor]="social">Siguenos</button>
                        <mat-menu #social="matMenu">
                            <a mat-menu-item target="_blank" rel="noopener" href="//open.spotify.com/artist/3Saso3ghsK3PYSromArnYW" alt="Spotify Servicentro">
                                <fa-icon class="mat-icon" [icon]="faSpotify"></fa-icon>
                                <span>Spotify</span>
                            </a>
                            <a mat-menu-item target="_blank" rel="noopener" href="//itunes.apple.com/artist/servicentro/1237982586" alt="Apple Music Servicentro">
                                <fa-icon class="mat-icon" [icon]="faApple"></fa-icon>
                                <span>Apple Music</span>
                            </a>
                            <a mat-menu-item target="_blank" rel="noopener" href="//soundcloud.com/servicentro" alt="SoundCloud Servicentro">
                                <fa-icon class="mat-icon" [icon]="faSoundcloud"></fa-icon>
                                <span>SoundCloud</span>
                            </a>
                            <a mat-menu-item target="_blank" rel="noopener" href="//www.facebook.com/Servicentrocl/" alt="Facebok Servicentro">
                                <fa-icon class="mat-icon" [icon]="faFacebook"></fa-icon>
                                <span>Facebok</span>
                            </a>
                            <a mat-menu-item target="_blank" rel="noopener" href="//www.youtube.com/Servicentrocl" alt="Youtube Servicentro">
                                <fa-icon class="mat-icon" [icon]="faYoutube"></fa-icon>
                                <span>Youtube</span>
                            </a>
                            <a mat-menu-item target="_blank" rel="noopener" href="//www.instagram.com/servicentrocl/" alt="Instagram Servicentro">
                                <fa-icon class="mat-icon" [icon]="faInstagram"></fa-icon>
                                <span>Instagram</span>
                            </a>
                            <a mat-menu-item target="_blank" rel="noopener" href="//www.tiktok.com/@servicentrocl/" alt="TikTok Servicentro">
                                <fa-icon class="mat-icon" [icon]="faTiktok"></fa-icon>
                                <span>TikTok</span>
                            </a>
                        </mat-menu>
                        <button mat-icon-button fxFlex="auto" (click)="themeToggle()" aria-label="tema" matTooltip="Tema">
                            <mat-icon>format_color_fill</mat-icon>
                        </button>
                    </div>
                </mat-toolbar-row>
            </mat-toolbar>
            <section>
                <router-outlet></router-outlet>
            </section>
            <app-footer></app-footer>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>