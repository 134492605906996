
<app-header [nombre]="pagina"></app-header>
<div m-10 fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="10px">
    <mat-card fxFlex.gt-sm="50%">
        <mat-card-header>
            <picture mat-card-avatar>
                <source type="image/webp" srcset="assets/img/albums/ConstruyamosEsperanza.webp">
                <img mat-card-avatar src='assets/img/albums/ConstruyamosEsperanza.jpg' alt='Construyamos Esperanza'>
            </picture>
            <mat-card-title>Construyamos Esperanza</mat-card-title>
            <mat-card-subtitle>2017</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <mat-accordion>
                <mat-expansion-panel tema>
                    <mat-expansion-panel-header>
                        <mat-panel-title>Construyamos Esperanza</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row wrap" fxLayoutGap="20px" fxLayout.xs="column">
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                En esta tierra lejana de contrastes y color
                                <br>Desde el norte gritan fuerte y en el sur alguien oyó
                                <br>Que por la cordillera
                                <br>Viene un rió que en el mar todos esperan
                            </p>
                            <p class="text-center">
                                Dicen que sus aguas claras pueden limpiar
                                <br>Los rostros y las manos de los que buscan la paz
                            </p>
                            <p class="text-center">
                                Construyamos Esperanza en la unión y en la razón
                                <br>Vamos a buscar la fraternidad solo abre el corazón
                                <br>Lo que Cristo nos enseña sin color ni distinción
                                <br>Es el amor
                            </p>
                            <p class="text-center">
                                Bienvenido Francisco
                                <br>Bienvenido Francisco
                            </p>
                            <p class="text-center">
                                Viene el tiempo en que todos nos prestemos atención
                                <br>Y miremos hacia el lado acogiendo sin rencor
                                <br>Abriendo las puertas
                                <br>A un mundo en que el amor todo lo espera
                            </p>
                            <p class="text-center">
                                Y si trabajamos juntos puede pasar
                                <br>Que sanemos las heridas y vivamos en la paz
                            </p>
                        </div>
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Construyamos esperanza en la unión y en la razón
                                <br>Vamos a buscar la fraternidad solo abre el corazón
                                <br>Lo que Cristo nos enseña sin color ni distinción
                            </p>
                            <p class="text-center">
                                Es el amor
                                <br>Es el amor
                            </p>
                            <p class="text-center">
                                Bienvenido Francisco
                                <br>Bienvenido Francisco
                            </p>
                            <p class="text-center">
                                (Bienvenido Francisco)
                                <br>Te estamos esperando para escuchar
                                <br>(Bienvenido Francisco)
                                <br>Tus palabras son agua que puede lavar
                                <br>(Bienvenido Francisco)
                                <br>El rostro y las manos de aquellos que buscan la paz
                            </p>
                            <p class="text-center">
                                (Bienvenido Francisco)
                                <br>Bienvenido
                                <br>Bienvenido
                                <br>Bienvenido
                            </p>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-card-content>
    </mat-card>
    <div fxFlex.gt-sm="calc(30% - 10px)">
        <iframe mb-20 width="100%" class="mat-elevation-z5" allow="autoplay *; encrypted-media *;" frameborder="0" height="450" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
            src="https://embed.music.apple.com/cl/album/construyamos-esperanza-single/1314181608?app=music" ></iframe>
    </div>
</div>