<app-header [nombre]="pagina"></app-header>
<mat-tab-group color="accent">
    <mat-tab label="Biografía">
        <div m-35 fxLayout="column" fxLayoutAlign="center" fxLayoutGap="50px">
            <section>
                <p>Servicentro es una banda de rock-pop nacida en Santiago de Chile en agosto
                    de 2011, que por más de 10 años se ha presentado en diversos escenarios del
                    país. En septiembre del 2014 se presentaron en el encuentro de música y
                    oración Hallel en la ciudad de Franca, Brasil.</p>
                <p>En mayo del 2017 se lanza el primer disco de la agrupación titulado “kilómetro
                    1”, con 8 temas originales y un cover.</p>
                <p>En Enero del 2018, durante la visita del Papa Francisco a Chile, la banda se
                    presenta en el Frontis del Santuario del Padre Hurtado frente a 5.000 personas
                    que esperan la llegada del Sumo Pontífice, por esos días lanzan el sencillo
                    “Construyamos Esperanza” que forma parte del set list oficial de la visita.</p>
                <p>Coni Castro y Edo Rigall se conocieron durante los años 90 en el coro de la
                    iglesia. Muchos años después se reencuentran y deciden experimentar con
                    otros sonidos, Coni propone hacer una banda de rock y Edo pone sobre la
                    mesa un antiguo proyecto; así comienza a fraguarse Servicentro. En agosto del
                    2011, para el primer ensayo de la banda se recluta a Rafael Cereceda como
                    baterista, Daniela Pozo en coros y a Kico Recart en la primera guitarra,
                    formación que se mantiene hasta principios del 2016, cuando Kico es sucedido
                    por Mauro Pinto, con quien graban el disco &quot;Kilómetro 1&quot;.</p>
                <p>En 2018 se suman Pilar Quezada en voces, Sebastián Viu en guitarra y Daniel
                    Vicentini en los teclados, quien acompaña a la banda en varias presentaciones
                    para luego retirarse a trabajar en proyectos personales.</p>
            </section>
        </div>
    </mat-tab>
    <mat-tab label="Miembros">
        <section>
            <div m-35 fxLayout="column" fxLayout.gt-xs="row wrap" fxLayoutGap="10px">
                <div fxLayout="row" *ngFor="let bio of bios">
                    <mat-card fxFlex class="example-card">
                        <blockquote>
                            <p>{{bio.fracesTipocas}}</p>
                            <cite fxLayout="row">
                                <img mat-card-avatar src="assets/img/bios/{{bio.imgUrl}}.jpg" alt="{{bio.nombre}}">
                                <div fxLayout="column" fxLayoutAlign="space-around stretch">
                                    <h2 fxFlex style="margin: 0 0 0 10px;">{{bio.nombre}}</h2>
                                    <small fxFlex style="margin: 0 0 0 10px;">{{bio.roles}}</small>
                                </div>
                            </cite>
                        </blockquote>
                        <mat-card-content>

                            <div><span class="mat-h3" matTooltip="Sobre Nombre">S.N.</span>: {{bio.apodos}}</div>
                            <div><span class="mat-h3" matTooltip="Regalo Útil">R.U.</span>: {{bio.regalo}}</div>
                            <div><span class="mat-h3" matTooltip="Lo Que Nunca Se Vio">L.Q.N.S.V.</span>: {{bio.lqnsv}}
                            </div>
                            <div><span class="mat-h3" matTooltip="Sueño Frustrado">S.F.</span>: {{bio.sf}}</div>
                            <div><span class="mat-h3" matTooltip="Se Le Puede Ver">S.L.P.V.</span>: {{bio.slpv}}</div>
                        </mat-card-content>
                        <mat-card-actions>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </div>
        </section>
    </mat-tab>
</mat-tab-group>