<div m-5 fxLayout="column" fxLayout.gt-xs="row wrap" >
    <div m-5 *ngFor='let galeria of galerias' fxFlex.sm="calc(50% - 10px)" fxFlex.md="calc(33% - 10px)" fxFlex.gt-md="calc(25%- 10px)">
        <mat-card>
            <mat-card-header>
                <mat-card-title>{{galeria.descripcion}}</mat-card-title>
                <mat-card-subtitle>{{galeria.ano}} - {{galeria.ciudad}}, {{galeria.pais}}</mat-card-subtitle>
            </mat-card-header>
            <img mat-card-image src='assets/img/galerias/{{galeria.nombre}}/{{galeria.fotos[0]}}'
                alt='Servicentro {{galeria.ano}} - {{galeria.descripcion}}'>
            <mat-card-actions>
                <button mat-raised-button color="accent" routerLink='{{galeria.id}}'>VER</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>