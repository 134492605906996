<div m-5 fxLayout="column" fxLayout.gt-xs="row wrap">
    <div m-5 *ngFor='let video of videos' fxFlex.sm="calc(50% - 10px)" fxFlex.md="calc(33% - 10px)" fxFlex.gt-md="calc(25%- 10px)">
        <mat-card>
            <mat-card-header>
                <mat-card-title>{{video.nombre}}</mat-card-title>
                <mat-card-subtitle>{{video.sub}}</mat-card-subtitle>
            </mat-card-header>
            <div mat-card-image m-5 mb-20 class="video-container">
                <iframe [src]="getURL(video.youtubeId) | safe" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
            </div>
        </mat-card>
    </div>

</div>