<app-header [nombre]="pagina"></app-header>
<div fxLayout="column" fxLayoutAlign="space-evenly center">
    <mat-card fxFlex.gt-sm="50%">
        <mat-card-header>
            <img mat-card-avatar src='favicon.ico' alt='Kilómetro 1'>
            <mat-card-title>Otros</mat-card-title>
            <mat-card-subtitle>sin fecha</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <mat-accordion>
                <mat-expansion-panel tema>
                    <mat-expansion-panel-header>
                        <mat-panel-title>Sáname</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row wrap" fxLayoutGap="20px" fxLayout.xs="column">
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Sáname por favor
                                <br>Que ya no puedo más escapar de tu amor
                                <br>Sáname
                                <br>Es tu voz la que está en mi interior de que puedo estar mejor
                            </p>
                            <p class="text-center">
                                Dame fuerza, dame luz del sol
                                <br>Porque sé que aunque duela me cuidará mi Dios
                            </p>
                            <p class="text-center">
                                Debo empezar a caminar contigo
                                <br>Orar, crecer, amar, creer y empezar a actuar
                                <br>Sáname que no puedo por mi mismo
                                <br>He caído en un abismo, sólo tú puedes salvarme Señor
                            </p>
                            <p class="text-center">
                                Te quiero agradecer por mi familia y mis amigos
                                <br>Me ayudaron a ver que siempre estarán conmigo
                                <br>La soledad no me visitará porque los tengo a ellos
                                <br>Y si un día se van
                            </p>
                        </div>
                        <div fxFlex.gt-sm="calc(50% - 20px)" fxFlex.lt-md="calc(100% - 20px)">
                            <p class="text-center">
                                Tú me acompañarás, tú me guiarás
                                <br>En cada uno de mis pasos, conmigo estarás
                            </p>
                            <p class="text-center">
                                Sáname que quiero cantar
                                <br>Al mundo quiero gritar que tú nos vienes a salvar
                                <br>Manifiéstate, estremece nuestro ser
                                <br>Ayúdanos a creer para empezar a actuar y hacer el bien
                            </p>
                            <p class="text-center">
                                Sáname que quiero cantar
                                <br>Al mundo quiero gritar que tú nos vienes a salvar
                                <br>Manifiéstate, estremece nuestro ser
                                <br>Ayúdanos a creer para empezar a actuar y hacer el bien
                            </p>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-card-content>
    </mat-card>
</div>
